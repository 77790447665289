/* LEFT */

.skill_header {
  font-family: "Work Sans", sans-serif;
  font-weight: 800;
  font-size: 45px;
  /* margin: 0 0 0 35%; */
  background: linear-gradient(to right, #f8b195, #f67280, #c06c84);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  width: 370px;
}

/* RIGHT */
.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0%;
  padding-top: 15%;
  margin-left: 0;
  position: absolute;
  right: 0;
  /* overflow: hidden; */
}

.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
}
.cubespinner div {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0px lightyellow;
}
.face1 {
  transform: translateZ(200px);
  color: #dd0031;
}
.face2 {
  transform: rotateY(90deg) translateZ(200px);
  color: #f06529;
}
.face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(200px);
  color: #28a4d9;
}
.face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(200px);
  color: #5ed4f4;
}
.face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(200px);
  color: #5ed4f4;
}
.face6 {
  transform: rotateX(-90deg) translateZ(200px);
  color: #ec4d28;
}




@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}
