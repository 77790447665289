:root {
  /* background-color: none; */
}

@font-face {
  font-family: Font_1;
  src: url("./Font/CoolveticaRg-Regular.woff2");
}
@font-face {
  font-family: Coding_Style;
  src: url("./Font/LaBelleAurore.woff");
}

body::-webkit-scrollbar {
  display: none;
}

html {
  background: linear-gradient(45deg, #24242394, #373a41, #605d77, #5f4159) ;
  /* background: linear-gradient(45deg, #f4ecf4, #ddbce1, #c38aca, #a37ba3) ; */
  background-size: 400% 400%;
  animation: gradient 4s ease-in-out infinite;
  /* height: 100vh; */
  font-family: Font_1;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
