.Intro {
   
}

/* left side styling */
.i-left {
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 3rem;
    justify-content: space-evenly;

}
.i-name {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}
.i-name > :nth-child(1) {
    font-weight: bold;
    font-size: 3rem;
    color: #8ab2d6;
    font-family: "Fira Code", monospace;
    font-weight: 800;
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    width: 200px;
    animation: type 2s steps(40, end) forwards;
}
.i-name > :nth-child(2) {
    font-family: "Work Sans", sans-serif;
    font-weight: 800;
    font-size: 45px;
    /* margin: 0 0 0 35%; */
    background: linear-gradient(to right, #f8b195, #f67280, #c06c84);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    width: 370px;
    opacity: 0;
    animation-delay: 5s;
    animation: type 5s steps(40, end) 2s  forwards;
}
.i-name > :nth-child(3) {
    /* font-weight: 100; */
    /* font-size: 14px; */
    /* color: var(--gray); */
    margin-top: 10px;
}

/* right side styling */
.i-right {
/* height: '100vh'; */
display: flex;
justify-content: center;
background-color: rgba(232,48,48,0.18);
box-shadow: 0px 32px 364px 200px rgba(232,48,48,0.18);
-webkit-box-shadow: 0px 32px 364px 200px rgba(232,48,48,0.18);
-moz-box-shadow: 0px 32px 364px 200px rgba(232,48,48,0.18);
}
.i-right > * {
    /* position: absolute; */
    /* z-index: 1; */
}



@keyframes type {
    0% {
        width: 1%;
        opacity: 1;
    }
    1%,
    99% {
        border-right: 1px solid orange;
        opacity: 1;
    }
    100% {
        /* border-right: none; */
        opacity: 1;
    }
}
