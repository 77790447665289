.header{
     font-family: "Work Sans", sans-serif;
    font-weight: 800;
    font-size: 45px;
    /* margin: 0 0 0 35%; */
    background: linear-gradient(to right, #f8b195, #f67280, #c06c84);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    width: 370px;
}

@media only screen and (max-width: 700px) {
    .header {
        /* font-size: 4px; */
        opacity: 0;
    }
}